* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* This class delete backgroundColor when hover in MenuButton JOY UI component */
.MuiIconButton-root:hover{
  background-color: transparent !important;
}

/* This change pagination bullets when active of swiper */
.swiper-pagination-bullet-active {
  background-color: black !important;
}

.swiper-button-next, .swiper-button-prev {
  color: black !important;
  font-weight: 600;
}

/* .css-1aquho2-MuiTabs-indicator{
  background-color: #99791C !important;
} */

.MuiTabs-indicator{
  background-color: #99791C !important;
  height: 3px !important;

}

.columnContainer {
  width: 90% !important;
  /* max-width: 1450px; */
  margin: 0px auto 0px auto !important;
}

/* User info input styles when disabled */
.css-1esgoju-MuiInputBase-root-MuiInput-root.Mui-disabled:before, .css-eimus0-MuiInputBase-root-MuiInput-root.Mui-disabled:before {
  border-bottom-style: solid !important;
}

/* User info picker input styles */
.MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-radius: 0px !important;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important; */
}

/* .MuiOutlinedInput-notchedOutline:hover {
  border: none !important;
  border-radius: 0px !important;
  border-bottom: 1px solid black !important;
} */
