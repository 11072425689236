.link{
   float:right;
   color:#99791C;
   font-size: 20px;
   font-family: 'weblysleekuisb';
}

.promotion{
   position: absolute;
   margin: 20px 0 0 0;
}

.promotion p{
 position: absolute;
 margin: 0;
 color: white;
 font-family: 'HudsonNYSerif';
}
.promotion p:first-child{
 top: 50%;
 transform: translate(-55%, -90%);
 left: 50%;
 font-weight: 400;
 font-size: 25px;
 font-family: 'HudsonNYSerif';
}
.promotion p:nth-child(2){
 top: 47%;
 transform: translate(-50%, 0%);
 left: 50%;
 font-size: 14px;
 margin-top: 2px;
}