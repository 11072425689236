
.icono {
    display: inline-block;
    text-align: center;
}

.modal-container {
    position: relative;
    width: 100%;
    height: 500px;
    margin-top: -380px;
}

.cartModal {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.cartModalContent{
    background-color: white;
    padding: 10px;
    border-radius:25px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); 
    text-align: center;
}
.contentIcons{
    display: inline-flex;
    justify-items: center;
    align-items: center;
    gap: 5px;
    padding-bottom: 10px;
}
